import Button from "@mui/material/Button";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import SubHeader from "src/components/SubHeader";
import { DONATE_URL } from "src/constants";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
export default function CovidPage() {
  return (
    <BlogLayout slug="growth-resilience-covid">
      <Paragraph>
        The COVID-19 pandemic has been one of the biggest challenges for our
        entire world. For people experiencing homelessness in Oakland and
        Alameda County, it was an especially heavy hit.{" "}
        <b>
          At EOCP, we maneuvered with the flexibility, creativity, and deep
          commitment that we've embodied from day one of doing this work.
        </b>
      </Paragraph>
      <SubHeader sx={{ my: 4 }}>Responding to crisis</SubHeader>
      <Paragraph>
        From the arrival of the coronavirus in Alameda County, our team took
        action to pivot our daily protocols.{" "}
        <b>
          We prioritized keeping all the people we serve safe and healthy —
          those in our shelters, on the streets, and in newly placed housing.
        </b>
      </Paragraph>
      <Paragraph>
        In our shelters, our everyday practice is to meet each individual where
        they’re at, and support them from there. We learned by constantly
        adapting our guidelines and ensuring smooth communication with
        residents. It was difficult but necessary to put in place stricter
        protocols for health screening, intake, and residents' behavior. We
        relocated those with chronic illnesses, and created social distancing
        measures in the dorms and dining rooms. In the most challenging weeks,
        we required that everyone stay inside to benefit our community’s
        collective health. Fears and discouragement ran high, and the emotional
        toll set in. Childcare paused, and families cohabitated 24/7, creating
        new dynamics to navigate and support.{" "}
        <b>
          EOCP stayed our course: We listened to every individual to discern the
          most nurturing routes of action during an uncertain time.
        </b>
      </Paragraph>
      <Paragraph>
        An essential team of kitchen and administrative staff kept all our
        shelters open and operating. Our case management team took on a rotating
        schedule to ensure that residents always had a familiar face for
        hands-on services. As the months progressed, we went through outbreak
        scares and quarantine responses. Our incredible programming team
        organized small group activities like movie nights, painting, and chess,
        and store runs or quick walks.{" "}
        <b>Residents stuck it through, and our staff stayed resilient.</b>
      </Paragraph>
      <SubHeader sx={{ my: 4 }}>Strengthening teamwork</SubHeader>
      <Paragraph>
        Our team adopted new approaches to reach those we serve and get them
        housed, working closely with critical community partners. We established
        a new virtual system for the housing search and placement process,
        therapy, and support for employment and life skills. There were hurdles,
        such as limited computer literacy and internet access, and wrangling
        many Zoom links. Our partnership with{" "}
        <Link href="https://oaklandreach.org/hub/">The Oakland REACH</Link>{" "}
        secured technology support for families. We minimized confusion about
        accessing benefits for the massive number of people who lost income.
      </Paragraph>
      <Paragraph>
        For the first six months of the pandemic, we worked to streamline our
        internal processes and align with the shifting practices at{" "}
        <Link href="http://www.oakha.org/Pages/default.aspx">
          Oakland Housing Authority
        </Link>{" "}
        (OHA),{" "}
        <Link href="https://www.alamedacountysocialservices.org/">
          Alameda Social Services Agency
        </Link>{" "}
        (SSA), and other core partners. After getting in sync, we were together
        able to support recently housed individuals and families with extended
        eligibility for rent subsidies, food stamps, and health benefits. Once
        both EOCP and OHA were working remotely, we were able to conduct
        inspections of prospective housing units via video calls.{" "}
        <b>
          With coordinated partnership, we pushed forward housing placements and
          made sure no one fell through the cracks, even while implementing new
          systems.
        </b>
      </Paragraph>
      <Paragraph>
        Tightening our relationship with{" "}
        <Link href="https://www.achch.org/">
          Alameda County Healthcare for the Homeless
        </Link>{" "}
        (ACHCH) has allowed us to serve not just our shelter residents, but also
        the broader community, as a designated site for vaccination and COVID
        testing. Our weekly meetings with ACHCH have empowered our on-site team
        to practice effective contact tracing and temperature and symptom
        checks.
      </Paragraph>
      <Paragraph>
        <b>We are grateful for community solidarity.</b> EOCP partnered with
        hotels, funding partners, and other allied community organizations to
        provide shelter. At our hotel partner sites, hotel owners became more
        aware of residents’ needs and spoke up to Case Managers, who were then
        better able to anticipate and provide for them. Landlords of suitable
        permanent housing units set up key lock-boxes and other adjustments to
        ensure in-person visits could be no-contact.
      </Paragraph>
      <Paragraph>
        The pandemic has brought all hands on deck. Across EOCP departments, our
        team members have grown professionally, collaborated with our partners,
        and stepped out of their comfort zones.{" "}
        <b>
          With dedication and compassion, our staff have rolled up their sleeves
          to keep being a source of motivation and action for those we serve.
        </b>
      </Paragraph>
      <SubHeader sx={{ my: 4 }}>Following through</SubHeader>
      <Paragraph>
        As we know, this pandemic is not over. With new variants, our team stays
        on alert to keep individuals and families at EOCP safe. As California
        has “opened up”, rents in Oakland have risen. It’s tough to find secure
        housing in areas that are affordable, familiar, and allow people to live
        near their jobs or schools.{" "}
      </Paragraph>
      <Paragraph>
        However, our services are more readily available. With employment
        counseling, people are attending job fairs, and finding new and stable
        income. Parents and children are able to work on their bond and new
        routines, now that schools have reopened. Adults of all ages are
        participating in the mental health support, substance use care, and life
        skills workshops — now with tips for staying healthy in the pandemic.
      </Paragraph>
      <Paragraph>
        With weekly COVID testing and regular on-site vaccination, our shelters
        have become safer and less fearful. We are proud that more of our
        residents know that being vaccinated is important to keep them safe, and
        that it prevents community spread.{" "}
      </Paragraph>
      <Paragraph>
        We see our staff persevering, sharing accolades and appreciations with
        each other, and building new team rituals while staying as committed as
        ever to our people. As one EOCP Case Manager shared,
        <b>
          {" "}
          “We have the right heart, and the right compassion. We don’t give up
          on getting you somewhere. No matter what, we’re here, and we can give
          you the tools to sustain yourself.”
        </b>{" "}
        Our mission, throughout this hard time, has stayed the same.
      </Paragraph>
      <SubHeader sx={{ my: 3 }}>
        Want to be part of this important work?{" "}
        <Link to="/work-with-us">We’re hiring!</Link>
      </SubHeader>
      <Paragraph>
        <Button href={DONATE_URL} variant="contained" size="large">
          Support EOCP
        </Button>
      </Paragraph>
    </BlogLayout>
  );
}
